import React, { Component } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import UserContext from "../../context/UserContext";
import InfoButton from "../Common/InfoButton";
// import { withAuth } from '@okta/okta-react';
import { fetchWithAuth } from "../../common/FetchUtil";

import "@progress/kendo-theme-default/dist/all.css";
import "./CancelBooking.css";

class CancelBooking extends Component {
  constructor(props) {
    super(props);
    this.state = { cancelAll: false, dialogVisible: false };
  }

  _onCancelAllChanged = () => {
    this.setState({ cancelAll: !this.state.cancelAll });
  };

  _toggleDialog = () => {
    this.setState({
      dialogVisible: !this.state.dialogVisible,
    });
  };

  _closeDialog = () => {
    this.setState({
      dialogVisible: false,
      showErrorMessage: false,
      message: "",
    });
  };

  _cancelBooking = () => {
    ///api/bookings/1
    fetchWithAuth(
      this.props.auth,
      process.env.REACT_APP_BASE_API_URL +
        "/api/bookings/" +
        this.props.bookingId +
        "?includeAllOccurances=" +
        this.state.cancelAll,
      {
        method: "DELETE",
      }
    )
      .then((response) => response.json())
      .then((json) => {
        if (json.errorCode !== -1) {
          this.setState({
            showErrorMessage: true,
            showSuccessMessage: false,
            message: json.message,
          });
        } else {
          this.setState({
            showErrorMessage: false,
            showSuccessMessage: true,
            message: json.message,
          });
          setTimeout(() => {
            window.location.href = "/";
          }, 5000);
        }
      })
      .catch((error) => {
        this.setState({
          showErrorMessage: true,
          showSuccessMessage: false,
          message: "There was an error cancelling your booking.",
        });
      });
  };

  render() {
    let buttonText = "Cancel Booking";

    if (this.state.cancelAll === true) {
      buttonText = "Cancel All Bookings";
    }
    let cancelAllCheckbox = "";
    if (this.props.isRecurringBooking === true) {
      cancelAllCheckbox = (
        <label className="au-control-input au-control-input-markin-top">
          <input
            className="au-control-input__input"
            type="checkbox"
            checked={this.state.cancelAll}
            onChange={this._onCancelAllChanged}
          ></input>
          <span className="au-control-input__text">Cancel all occurrences</span>
          <InfoButton infoMessage="Choosing this option will cancel booking for the entire series" />
        </label>
      );
    }

    let message = (
      <p style={{ margin: "25px", textAlign: "center" }}>
        Are you sure you want to cancel the booking?
      </p>
    );
    let errorMessage = (
      <p style={{ margin: "25px", textAlign: "center", color: "#B81237" }}>
        {this.state.message}
      </p>
    );
    let successMessage = (
      <p style={{ margin: "25px", textAlign: "center", color: "#00A908" }}>
        {this.state.message}
      </p>
    );

    return (
      <div>
        <button
          className="au-btn au-btn--secondary"
          onClick={this._toggleDialog}
        >
          {buttonText}
        </button>
        {cancelAllCheckbox}
        {this.state.dialogVisible && (
          <Dialog
            title={"Confirm cancellation of booking"}
            onClose={this._toggleDialog}
          >
            {!this.state.showErrorMessage &&
              !this.state.showSuccessMessage &&
              message}
            {this.state.showErrorMessage &&
              !this.state.showSuccessMessage &&
              errorMessage}
            {!this.state.showErrorMessage &&
              this.state.showSuccessMessage &&
              successMessage}
            <DialogActionsBar>
              {!this.state.showErrorMessage &&
                !this.state.showSuccessMessage && (
                  <button
                    className="au-btn au-btn--secondary au-btn-margin-left"
                    onClick={this._closeDialog}
                  >
                    No
                  </button>
                )}
              {!this.state.showErrorMessage &&
                !this.state.showSuccessMessage && (
                  <button
                    className="au-btn au-btn--primary au-btn-margin-left"
                    onClick={this._cancelBooking.bind(
                      this,
                      this.props.bookingId
                    )}
                  >
                    Yes
                  </button>
                )}

              {(this.state.showErrorMessage ||
                this.state.showSuccessMessage) && (
                <button
                  className="au-btn au-btn--secondary au-btn-margin-left"
                  onClick={this._closeDialog}
                >
                  Close
                </button>
              )}
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    );
  }
}

CancelBooking.contextType = UserContext;

// export default withAuth(CancelBooking);
export default CancelBooking;
