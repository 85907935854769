import {ViewTypes} from 'react-big-scheduler';
import React from 'react';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';

export function getDateLabel(schedulerData, viewType, startDate, endDate){
    let start = schedulerData.localeMoment(startDate);
    let end = schedulerData.localeMoment(endDate);
    let dateLabel = start.format('DD/MM/YYYY');

    if(viewType === ViewTypes.Week || (start !== end && (
        viewType === ViewTypes.Custom || viewType === ViewTypes.Custom1 || viewType === ViewTypes.Custom2
    ))) {
        dateLabel = `${start.format('DD')}-${end.format('DD/MM/YYYY')}`;
        if(start.month() !== end.month())
            dateLabel = `${start.format('DD')}-${end.format('DD/MM/YYYY')}`;
        if(start.year() !== end.year())
            dateLabel = `${start.format('DD/MM/YYYY')}-${end.format('DD/MM/YYYY')}`;
    }
    else if(viewType === ViewTypes.Month){
        dateLabel = start.format('MMMM YYYY');
    }
    else if(viewType === ViewTypes.Quarter){
        dateLabel = `${start.format('DD/MM')}-${end.format('DD/MM, YYYY')}`;
    }
    else if(viewType === ViewTypes.Year) {
        dateLabel = start.format('YYYY');
    }

    return dateLabel;
}

export function eventItemTemplateResolver(schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight, agendaMaxEventWidth){
    let borderWidth = isStart ? '4' : '0';
    let borderColor =  'rgba(0,139,236,1)', backgroundColor = '#80C5F6';
    let titleText = schedulerData.behaviors.getEventTextFunc(schedulerData, event);
    let color = "#000000";

    if (event.other && event.other.booking && event.other.booking.bookingStatusId && event.other.booking.bookingStatusId === 1) //tentative
    {
        borderColor = "#f9be00";
        backgroundColor = "#f9be00";
    }
    else if (event.other && event.other.booking && event.other.booking.bookingStatusId && event.other.booking.bookingStatusId === 2) //approved
    {
        borderColor = "#00A908";
        backgroundColor = "#00A908";
    }
    else if (event.other && event.other.booking && event.other.booking.bookingStatusId && event.other.booking.bookingStatusId === 3) //tentative in conflict
    {
        borderColor = "#DC5800";
        backgroundColor = "#DC5800";
    }
    else if (event.other && event.other.booking && event.other.booking.bookingStatusId && event.other.booking.bookingStatusId === 4) //Approved in conflict
    {
        borderColor = "#B81237";
        backgroundColor = "#B81237";
        color = "#FFFFFF";
    }
    
    let divStyle = {borderLeft: borderWidth + 'px solid ' + borderColor, backgroundColor: backgroundColor, height: mustBeHeight, color: color };
    if(!!agendaMaxEventWidth)
        divStyle = {...divStyle, maxWidth: agendaMaxEventWidth};

    return <div key={event.id} className={mustAddCssClass} style={divStyle}>
        <span style={{marginLeft: '4px', lineHeight: `${mustBeHeight}px` }}>{titleText}</span>
    </div>;
}

export function eventItemPopoverTemplateResolver(schedulerData, eventItem, title, start, end, statusColor){
    let bookButton = "";
    let cancelButton = "";
    let changeButton = "";

    if (eventItem.other && eventItem.other.newEvent === true)
    {
        bookButton = (<button className="au-btn au-btn--tertiary" onClick={()=>{eventItem.other.book(schedulerData, eventItem);}}>Book</button>);
        cancelButton = (<button className="au-btn au-btn--tertiary" onClick={()=>{eventItem.other.cancel(schedulerData, eventItem);}}>Cancel new booking</button>);
    }
    else if (eventItem.other && eventItem.other.editableEvent === true)
    {
        changeButton = (<button className="au-btn au-btn--tertiary" onClick={()=>{eventItem.other.change(schedulerData, eventItem);}}>Change</button>);
    }

    return (
        
        <div style={{width: '400px'}}>
            <Row type="flex" align="middle">
                <Col span={2}>
                    <div className="status-dot" style={{backgroundColor: statusColor}} />
                </Col>
                <Col span={22} className="overflow-text">
                    <span className="header2-text" title={title}>{title}</span>
                </Col>
            </Row>
            <Row type="flex" align="middle">
                <Col span={2}>
                    <div />
                </Col>
                <Col span={22}>
                <span className="header1-text">{start.format('h:mm a')}</span><span className="help-text" style={{marginLeft: '8px'}}>{start.format('MMM D')}</span><span className="header2-text"  style={{marginLeft: '8px'}}>-</span><span className="header1-text" style={{marginLeft: '8px'}}>{end.format('h:mm a')}</span><span className="help-text" style={{marginLeft: '8px'}}>{end.format('MMM D')}</span>
                </Col>
            </Row>
            <Row type="flex" align="middle">
                <Col span={2}>
                    <div />
                </Col>
                <Col span={11}>
                    {bookButton}
                    {changeButton}
                </Col>
                <Col span={11}>
                    {cancelButton}
                </Col>
            </Row>
        </div>
    );
}

export function globalSchedulerConfig(overrides){
    return {
        resourceName: "Courtrooms",
        calendarPopoverEnabled: false,
        nonWorkingTimeHeadColor: '#999999',
        nonWorkingTimeHeadBgColor: '#F4F7F9',
        nonWorkingTimeBodyBgColor: '#F4F7F9',
        taskName: 'Booking',
        eventItemLineHeight: 46,
        checkConflict: overrides ? overrides.checkConflict : true,
        nonAgendaOtherCellHeaderFormat: "ddd|DD/MM",
        schedulerWidth: overrides && overrides.schedulerWidth ? overrides.schedulerWidth : "100%",
        minuteStep: 30,
        movable: overrides ? overrides.movable : true,
        dayCellWidth: 30,
        creatable: overrides? overrides.creatable : true,
        views: [
            { viewName: 'Day', viewType: ViewTypes.Day, showAgenda: false, isEventPerspective: false},
            { viewName: 'Week', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false}
        ]
    }
}