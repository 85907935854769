import React, { Component }  from 'react';
import Select from 'react-select';
import {filterList} from '../../../common/ListUtil';

class CourthouseSelect extends Component{
    flattenCourthouses(courthouse){
      return courthouse.original ? courthouse.original.courtrooms : [];
    }
  
    flattenHubs(hub){
      return hub.original ? hub.original.courthouses : [];
    }

    flattenRegions(region){
      return region.original ? region.original.hubs : [];
    }

    filteredItems(){
      var courthouses = this.props.state.courthouses;
      var hubs = this.props.state.hubs;
      var regions = this.props.state.regions;

      if (this.props.state.selectedRegions && this.props.state.selectedRegions.length > 0){
        regions = this.props.state.selectedRegions
      }

      if (this.props.state.selectedHubs && this.props.state.selectedHubs.length > 0){
        hubs = this.props.state.selectedHubs
      }

      if (this.props.state.selectedCourthouses && this.props.state.selectedCourthouses.length > 0){
        courthouses = this.props.state.selectedCourthouses
      }

      var filteredHubs = filterList(regions, hubs, this.flattenRegions);

      var filtredCourthouses = filterList(filteredHubs, courthouses, this.flattenHubs);

      return filterList(filtredCourthouses, this.props.state.courtrooms, this.flattenCourthouses);
   }
  
    render(){
        return (
        <span>
            <label htmlFor="select1">Courtrooms</label>
            <Select defaultValue={[]}
                    isMulti
                    name="colors"
                    options={this.filteredItems()}
                    className="basic-multi-select"
                    classNamePrefix="select" 
                    onChange={this.props.handleChange}
                    value={this.props.state.selectedCourtrooms}
                    />
        </span>
        )}
}

export default CourthouseSelect;