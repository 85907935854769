import React, { Component }  from 'react';
import "./InfoButton.css";

class InfoButton extends Component{
    constructor(props){
      super(props);
      this.state = { showInfoMessage: false };
    }
    _toggleInfoMessage = () =>{
        this.setState({showInfoMessage: !this.state.showInfoMessage});
    }

    render(){
        var infoMessage = "";
        if (this.state.showInfoMessage)
        {
            infoMessage = (<p className="au-callout au-callout-hover" aria-label="Callout description1">
            {this.props.infoMessage}
                </p>)
        }

        return (
            <span>
                <button className="au-btn au-btn--tertiary" onClick={this._toggleInfoMessage}>?</button>
                {infoMessage}
            </span>)
    }
}


export default InfoButton;