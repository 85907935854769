
import React, { Component }  from 'react';

class SearchCourtrooms extends Component{
    render(){
        return (
            <div className="form-item" style={{marginTop: 2.5 + 'rem'}}>
                <button className="au-btn" onClick={this.props.searchButtonClicked} disabled={this.props.searchEnabled}>Search</button>
            </div>
        )
    }
}


export default SearchCourtrooms;