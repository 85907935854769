import React, { Component } from "react";
// import { withAuth } from '@okta/okta-react';
import { fetchWithAuth } from "../../common/FetchUtil";
// eslint-disable-next-line
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import Select from "react-select";
import _ from "lodash";

class AdminCourtroomById extends Component {
  constructor(props) {
    super(props);

    this.state = {
      courtroom: {
        isAvailableForBooking: true,
        notes: "",
        courtroomFacilities: [],
        name: "",
        description: "",
        genericEmailAddress: "",
      },
      owners: [],
      facilities: [],
      selectedResourceOwners: [],
      showErrorMessage: false,
      showSuccessMessage: false,
      message: "",
      nameValidationMessage: null,
      descriptionValidationMessage: null,
      emailValidationMessage: null,
      courthouseValidationMessage: null,
    };
  }

  _fetchCourtroom = (courtroomId) => {
    fetchWithAuth(
      this.props.auth,
      process.env.REACT_APP_BASE_API_URL + "/api/courtrooms/" + courtroomId
    )
      .then((response) => response.json())
      .then((json) => this._updateCourtroomData(json));
  };

  _fetchOwners = () => {
    fetchWithAuth(
      this.props.auth,
      process.env.REACT_APP_BASE_API_URL + "/api/owners/"
    )
      .then((response) => response.json())
      .then((json) => {
        var selectedOwners = _.intersectionWith(
          json,
          this.state.courtroom.courtroomOwners,
          function (value1, value2) {
            return value2.userId === value1.id;
          }
        );
        this.setState({
          owners: this._mapToDropdownItems(json),
          selectedResourceOwners: this._mapToDropdownItems(selectedOwners),
        });
      });
  };

  _updateCourtroomData = (courtroom) => {
    if (courtroom.notes === null || courtroom.notes === undefined) {
      courtroom.notes = "";
    }

    this.setState({ courtroom: courtroom, errorMessage: null });
    this._fetchOwners();
    this._fetchFacilities();
    this._fetchCourthouses();
    this._fetchJurisdictions();
  };

  _mapToDropdownItems = (json) => {
    var items = json.map(function (entry) {
      var item = {
        value: entry.id,
        label: entry.emailAddress,
        original: entry,
      };
      return item;
    });
    return items;
  };

  _mapToDropdownItemsForIdNamePair = (json) => {
    var items = json.map(function (entry) {
      var item = { value: entry.id, label: entry.name, original: entry };
      return item;
    });
    return items;
  };

  _onAvailibilityChange = () => {
    var courtroom = this.state.courtroom;
    courtroom.isAvailableForBooking = !courtroom.isAvailableForBooking;
    this.setState({ courtroom: courtroom, errorMessage: null });
  };

  _onNotesChange = (event) => {
    var courtroom = this.state.courtroom;
    courtroom.notes = event.target.value;
    this.setState({ courtroom: courtroom });
  };

  _onNameChange = (event) => {
    var courtroom = this.state.courtroom;
    courtroom.name = event.target.value;
    this.setState({ courtroom: courtroom });
  };

  _onDescriptionChange = (event) => {
    var courtroom = this.state.courtroom;
    courtroom.description = event.target.value;
    this.setState({ courtroom: courtroom });
  };

  _onGenericEmailAddressChange = (event) => {
    var courtroom = this.state.courtroom;
    courtroom.genericEmailAddress = event.target.value;
    this.setState({ courtroom: courtroom });
  };

  _fetchFacilities = () => {
    fetchWithAuth(
      this.props.auth,
      process.env.REACT_APP_BASE_API_URL + "/api/facilities"
    )
      .then((response) => response.json())
      .then((json) => this.setState({ facilities: json, errorMessage: null }));
  };

  _fetchCourthouses = () => {
    fetchWithAuth(
      this.props.auth,
      process.env.REACT_APP_BASE_API_URL + "/api/courthouses"
    )
      .then((response) => response.json())
      .then((json) => {
        var selectedCourthouses = [];
        if (this.state.courtroom.courthouse)
          selectedCourthouses.push(this.state.courtroom.courthouse);
        this.setState({
          courthouses: this._mapToDropdownItemsForIdNamePair(json),
          selectedCourthouse:
            this._mapToDropdownItemsForIdNamePair(selectedCourthouses)[0],
        });
      });
  };

  _fetchJurisdictions = () => {
    fetchWithAuth(
      this.props.auth,
      process.env.REACT_APP_BASE_API_URL + "/api/jurisdictions"
    )
      .then((response) => response.json())
      .then((json) => {
        var selectedJurisdictions = _.intersectionWith(
          json,
          this.state.courtroom.courtroomUsages,
          function (value1, value2) {
            return value2.jurisdictionId === value1.id;
          }
        );
        this.setState({
          jurisdictions: this._mapToDropdownItemsForIdNamePair(json),
          selectedJurisdictions: this._mapToDropdownItemsForIdNamePair(
            selectedJurisdictions
          ),
        });
      });
  };

  _isAddCourtroom = () => {
    return this.props.match.params.id === "new";
  };

  componentDidMount = () => {
    if (this._isAddCourtroom() === false) {
      this._fetchCourtroom(this.props.match.params.id);
    } else {
      this._fetchOwners();
      this._fetchFacilities();
      this._fetchCourthouses();
      this._fetchJurisdictions();
    }
  };

  _isFacilityChecked = (facility) => {
    var index = _.findIndex(
      this.state.courtroom.courtroomFacilities,
      function (f) {
        return f.facilityId === facility.id;
      }
    );
    var checked = index > -1;

    return checked;
  };

  _getAmountForFacility = (facility) => {
    var index = _.findIndex(
      this.state.courtroom.courtroomFacilities,
      function (f) {
        return f.facilityId === facility.id;
      }
    );
    var checked = index > -1;
    var amount = "";
    if (checked) {
      var item = this.state.courtroom.courtroomFacilities[index];
      amount = item.amount === null ? "" : item.amount;
    }
    return amount;
  };

  _updateAmountForFacility = (facility, event) => {
    var courtroom = this.state.courtroom;
    var index = _.findIndex(courtroom.courtroomFacilities, function (f) {
      return f.facilityId === facility.id;
    });
    var checked = index > -1;
    if (!checked) return;

    var item = courtroom.courtroomFacilities[index];
    const re = /^[0-9\b]+$/;

    if (event.target.value !== "" && re.test(event.target.value)) {
      item.amount = event.target.value;
    } else {
      item.amount = null;
    }

    this.setState({ courtroom: courtroom });
  };

  _updateSelectedFacilities = (facility, event) => {
    var courtroom = this.state.courtroom;
    if (event.target.checked === false) {
      //remove the facility from the courtroom.
      _.remove(courtroom.courtroomFacilities, function (f) {
        return f.facilityId === facility.id;
      });
    } else if (event.target.checked === true) {
      courtroom.courtroomFacilities.push({
        amount: null,
        courtroomId: courtroom.id,
        facilityId: facility.id,
        id: 0,
      });
    }

    this.setState({ courtroom: courtroom });
  };

  _isNameValid = () => {
    if (this.state.courtroom.name.length === 0) {
      this.setState({
        nameValidationMessage: "Name is required.",
      });
      return false;
    } else if (this.state.courtroom.name.length > 50) {
      this.setState({
        nameValidationMessage: "Name cannot be more than 50 characters.",
      });
      return false;
    }

    this.setState({
      nameValidationMessage: null,
    });
    return true;
  };

  _isDescriptionValid = () => {
    if (this.state.courtroom.description.length === 0) {
      this.setState({
        descriptionValidationMessage: "Description is required.",
      });
      return false;
    } else if (this.state.courtroom.description.length > 200) {
      this.setState({
        descriptionValidationMessage:
          "Description cannot be more than 200 characters.",
      });
      return false;
    }

    this.setState({
      descriptionValidationMessage: null,
    });
    return true;
  };

  _isEmailAddressValid = () => {
    const re =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (this.state.courtroom.genericEmailAddress.length === 0) {
      this.setState({
        emailValidationMessage: "Email address is required.",
      });
      return false;
    } else if (re.test(this.state.courtroom.genericEmailAddress) === false) {
      this.setState({
        emailValidationMessage: "Email address is not valid.",
      });
      return false;
    }

    this.setState({
      emailValidationMessage: null,
    });
    return true;
  };

  _isCourthouseValid = () => {
    if (this.state.selectedCourthouse && this.state.selectedCourthouse.value) {
      this.setState({
        courthouseValidationMessage: null,
      });
      return true;
    } else {
      this.setState({
        courthouseValidationMessage: "Courthouse is required.",
      });
      return false;
    }
  };

  _isFormValid = () => {
    var nameValid = this._isNameValid();
    var descriptionValid = this._isDescriptionValid();
    var emailValid = this._isEmailAddressValid();
    var courthouseValid = this._isCourthouseValid();

    return nameValid && descriptionValid && emailValid && courthouseValid;
  };

  /**
        "OnlineStatus": "0",
		"Facilities": [[1, null],2,3,4,5],
		"Owners": [10,11,12,13],
		"Notes": "This is a note" 
     */
  _saveCourtroom = () => {
    this.setState({
      showErrorMessage: false,
      showSuccessMessage: false,
      message: null,
    });

    if (this._isFormValid() === false) {
      this.setState({
        showErrorMessage: true,
        showSuccessMessage: false,
        message: "There are validation errors on this page.",
      });
      return;
    }

    var payload = {
      IsOnline: this.state.courtroom.isAvailableForBooking,
      SelectedFacilities: this.state.courtroom.courtroomFacilities.map(
        function (f) {
          return { facilityId: f.facilityId, amount: f.amount };
        }
      ),
      SelectedOwners: this.state.selectedResourceOwners
        ? this.state.selectedResourceOwners.map(function (o) {
            return o.value;
          })
        : [],
      Notes: this.state.courtroom.notes,
      Name: this.state.courtroom.name,
      Description: this.state.courtroom.description,
      GenericEmailAddress: this.state.courtroom.genericEmailAddress,
      CourthouseId: this.state.selectedCourthouse.value,
      SelectedJurisdictions: this.state.selectedJurisdictions
        ? this.state.selectedJurisdictions.map(function (o) {
            return o.value;
          })
        : [],
    };

    var url =
      process.env.REACT_APP_BASE_API_URL +
      "/api/courtrooms/" +
      this.state.courtroom.id;
    var method = "PATCH";

    if (this._isAddCourtroom()) {
      url = process.env.REACT_APP_BASE_API_URL + "/api/courtrooms/";
      method = "POST";
    }

    fetchWithAuth(this.props.auth, url, {
      method: method,
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.errorCode !== -1) {
          this.setState({
            showErrorMessage: true,
            showSuccessMessage: false,
            message: json.message,
          });
        } else {
          this.props.history.push("/admin/courtrooms");
        }
      })
      .catch((error) => {
        this.setState({
          showErrorMessage: true,
          showSuccessMessage: false,
          message: "There was an error updating the courtroom.",
        });
      });
  };

  _getFacilityComponent(facility) {
    return (
      <div className="form-item" key={facility.id}>
        <label className="au-control-input">
          <input
            className="au-control-input__input"
            type="checkbox"
            checked={this._isFacilityChecked(facility)}
            onChange={this._updateSelectedFacilities.bind(this, facility)}
          ></input>
          <span className="au-control-input__text">{facility.name}</span>
        </label>
        {this._isFacilityChecked(facility) && (
          <div>
            Amount:
            <input
              className="au-text-input"
              type="text"
              style={{ maxWidth: "70px", marginLeft: "20px" }}
              value={this._getAmountForFacility(facility)}
              onChange={this._updateAmountForFacility.bind(this, facility)}
            ></input>
          </div>
        )}
      </div>
    );
  }

  _resourceOwnersChanged = (selectedOptions) => {
    this.setState({ selectedResourceOwners: selectedOptions });
  };

  _courthousesChanged = (selectedOptions) => {
    this.setState({ selectedCourthouse: selectedOptions });
  };

  _jurisdictionsChanged = (selectedOptions) => {
    this.setState({ selectedJurisdictions: selectedOptions });
  };

  render() {
    let facilitiesColumn1 = [];
    let facilitiesColumn2 = [];
    let facilitiesColumn3 = [];

    this.state.facilities
      .slice(0, (this.state.facilities.length + 1) / 3 + 0)
      .forEach((facility) => {
        facilitiesColumn1.push(this._getFacilityComponent(facility));
      });

    this.state.facilities
      .slice(
        (this.state.facilities.length + 1) / 3,
        (this.state.facilities.length + 1) / 3 +
          (this.state.facilities.length + 1) / 3
      )
      .forEach((facility) => {
        facilitiesColumn2.push(this._getFacilityComponent(facility));
      });

    this.state.facilities
      .slice(
        2 * ((this.state.facilities.length + 1) / 3),
        (this.state.facilities.length + 1) / 3 +
          2 * ((this.state.facilities.length + 1) / 3)
      )
      .forEach((facility) => {
        facilitiesColumn3.push(this._getFacilityComponent(facility));
      });

    let heading = "Manage courtroom and facilities";

    if (this._isAddCourtroom() === true) {
      heading = "Add a new courtroom";
    }

    return (
      <section className="au-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7">
              <h1>{heading}</h1>
            </div>
          </div>

          <div className="row">
            <div className="col-md-7">
              <div className="form-item">
                <label className="label--block">Name</label>
                <input
                  className="au-text-input empty-required"
                  onChange={this._onNameChange}
                  value={this.state.courtroom.name}
                  required
                  style={{ minWidth: "400px" }}
                ></input>
                {this.state.nameValidationMessage && (
                  <div>
                    <label style={{ color: "#B81237" }}>
                      {this.state.nameValidationMessage}
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-7">
              <div className="form-item">
                <label className="label--block">Description</label>
                <textarea
                  className="au-text-input au-text-input--block empty-required"
                  onChange={this._onDescriptionChange}
                  value={this.state.courtroom.description}
                ></textarea>
                {this.state.descriptionValidationMessage && (
                  <div>
                    <label style={{ color: "#B81237" }}>
                      {this.state.descriptionValidationMessage}
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-7">
              <div className="form-item">
                <label className="label--block">Generic Email Address</label>
                <input
                  type="email"
                  className="au-text-input empty-required"
                  onChange={this._onGenericEmailAddressChange}
                  value={this.state.courtroom.genericEmailAddress}
                  required
                  style={{ minWidth: "400px" }}
                ></input>
                {this.state.emailValidationMessage && (
                  <div>
                    <label style={{ color: "#B81237" }}>
                      {this.state.emailValidationMessage}
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-7">
              <div className="form-item">
                <label className="label--block">Availibility</label>
                <label className="au-control-input">
                  <input
                    className="au-control-input__input"
                    type="radio"
                    name="radio-operational-status"
                    checked={this.state.courtroom.isAvailableForBooking}
                    onChange={this._onAvailibilityChange}
                  ></input>
                  <span className="au-control-input__text">
                    Available for booking
                  </span>
                </label>
                <label className="au-control-input">
                  <input
                    className="au-control-input__input"
                    type="radio"
                    name="radio-operational-status"
                    checked={!this.state.courtroom.isAvailableForBooking}
                    onChange={this._onAvailibilityChange}
                  ></input>
                  <span className="au-control-input__text">
                    Not available for booking
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-7">
              <div className="form-item">
                <label className="label--block">Notes</label>
                <textarea
                  className="au-text-input au-text-input--block empty-required"
                  onChange={this._onNotesChange}
                  value={this.state.courtroom.notes}
                ></textarea>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">{facilitiesColumn1}</div>
            <div className="col-md-4">{facilitiesColumn2}</div>
            <div className="col-md-4">{facilitiesColumn3}</div>
          </div>

          <div className="row">
            <div className="col-md-7">
              <label htmlFor="select1">Resource owners</label>
              <Select
                defaultValue={[]}
                isMulti
                name="colors"
                options={this.state.owners}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={this._resourceOwnersChanged}
                value={this.state.selectedResourceOwners}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-7">
              <label htmlFor="select1">Courthouses</label>
              <Select
                defaultValue={[]}
                isClearable
                name="colors"
                options={this.state.courthouses}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={this._courthousesChanged}
                value={this.state.selectedCourthouse}
              />
              {this.state.courthouseValidationMessage && (
                <div>
                  <label style={{ color: "#B81237" }}>
                    {this.state.courthouseValidationMessage}
                  </label>
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-md-7">
              <label htmlFor="select1">Jurisdictions</label>
              <Select
                defaultValue={[]}
                isMulti
                name="colors"
                options={this.state.jurisdictions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={this._jurisdictionsChanged}
                value={this.state.selectedJurisdictions}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-2">
              <div className="form-item">
                <button className="au-btn" onClick={this._saveCourtroom}>
                  Save
                </button>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-item">
                <Link
                  className="au-btn au-btn--secondary"
                  to={"/admin/courtrooms/"}
                >
                  Cancel
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-item">
                {this.state.showErrorMessage && (
                  <label style={{ color: "#B81237" }}>
                    {this.state.message}
                  </label>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

// export default withAuth(AdminCourtroomById);
export default withRouter(AdminCourtroomById);
