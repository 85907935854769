import React, { Component } from "react";
// import { withAuth } from '@okta/okta-react';

class NotSystemAdmin extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="au-body">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              Add courtroom feature is only available for System administrators.
              Contact{" "}
              <a href="mailto:justicelinksupport@justice.nsw.gov.au">
                Court booking tool support.
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default withAuth(NotSystemAdmin);
export default NotSystemAdmin;
