import { useMsal } from "@azure/msal-react";
import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Callback = () => {
  const history = useHistory();
  const { instance } = useMsal();

  useEffect(() => {
    console.log("Callback loading...");
    const handleRedirect = async () => {
      await instance.handleRedirectPromise();
      history.push("/");
    };

    handleRedirect();
  }, [instance, history]);

  return <></>;
};

export default Callback;
