import React, { Component } from "react";
// import { withAuth } from '@okta/okta-react';
import { fetchWithAuth } from "../../common/FetchUtil";
// eslint-disable-next-line
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
} from "react-router-dom";

class AdminFacilityById extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facility: { name: "", description: "", id: 0 },
      errorMessage: null,
      nameValidationMessage: null,
      descriptionValidationMessage: null,
    };
  }

  _getFacilityById = (facilityId) => {
    fetchWithAuth(
      this.props.auth,
      process.env.REACT_APP_BASE_API_URL + "/api/facilities/" + facilityId
    )
      .then((response) => response.json())
      .then((json) => this.setState({ facility: json, errorMessage: null }));
  };

  _isAddFacility = () => {
    return this.props.match.params.id === "new";
  };

  _validateName = () => {
    if (this.state.facility.name.length === 0) {
      this.setState({
        nameValidationMessage: "Name is required.",
      });
      return false;
    } else if (this.state.facility.name.length > 50) {
      this.setState({
        nameValidationMessage: "Name cannot be more than 50 characters.",
      });
      return false;
    }

    this.setState({
      nameValidationMessage: null,
    });
    return true;
  };

  _validateDescription = () => {
    if (this.state.facility.description.length === 0) {
      this.setState({
        descriptionValidationMessage: "Description is required.",
      });
      return false;
    } else if (this.state.facility.description.length > 200) {
      this.setState({
        descriptionValidationMessage:
          "Description cannot be more than 200 characters.",
      });
      return false;
    }

    this.setState({
      descriptionValidationMessage: null,
    });
    return true;
  };

  _saveFacility = () => {
    var nameValidationPasses = this._validateName();
    var descriptionValidationPasses = this._validateDescription();
    if (descriptionValidationPasses && nameValidationPasses) {
      fetchWithAuth(
        this.props.auth,
        process.env.REACT_APP_BASE_API_URL +
          "/api/facilities/" +
          this.state.facility.id,
        {
          method: "POST",
          body: JSON.stringify(this.state.facility),
        }
      )
        .then((response) => response.json())
        .then((json) => {
          if (json.errorCode !== -1) {
            this.setState({
              showErrorMessage: true,
              showSuccessMessage: false,
              message: json.message,
            });
          } else {
            this.props.history.push("/admin/facilities");
          }
        })
        .catch((error) => {
          this.setState({
            showErrorMessage: true,
            showSuccessMessage: false,
            message: "There was an error deleting the facility.",
          });
        });
    }
  };

  _updateDescription = (event) => {
    var updated = this.state.facility;
    updated.description = event.target.value;
    this.setState({
      facility: updated,
    });
  };

  _updateName = (event) => {
    var updated = this.state.facility;
    updated.name = event.target.value;
    this.setState({
      facility: updated,
    });
  };

  componentDidMount = () => {
    if (this._isAddFacility() === false) {
      this._getFacilityById(this.props.match.params.id);
    }
  };

  render() {
    let heading = "Add courtroom facility";

    if (this._isAddFacility() === false) {
      heading = "Modify courtroom facilities";
    }

    return (
      <section className="au-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7">
              <h1>{heading}</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7">
              <div className="form-item">
                <label className="label--block">
                  Name <span className="form-required">(required)</span>
                </label>
                <input
                  className="au-text-input empty-required"
                  value={this.state.facility.name}
                  onChange={this._updateName}
                  required
                  style={{ minWidth: "400px" }}
                ></input>
                {this.state.nameValidationMessage && (
                  <div>
                    <label style={{ color: "#B81237" }}>
                      {this.state.nameValidationMessage}
                    </label>
                  </div>
                )}
              </div>
              <div className="form-item">
                <label className="label--block">
                  Description <span className="form-required">(required)</span>
                </label>
                <textarea
                  className="au-text-input au-text-input--block empty-required"
                  onChange={this._updateDescription}
                  value={this.state.facility.description}
                  required
                ></textarea>
                {this.state.descriptionValidationMessage && (
                  <label style={{ color: "#B81237" }}>
                    {this.state.descriptionValidationMessage}
                  </label>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <div className="form-item">
                <button className="au-btn" onClick={this._saveFacility}>
                  Save
                </button>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-item">
                <Link
                  className="au-btn au-btn--secondary"
                  to={"/admin/facilities/"}
                >
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

// export default withAuth(AdminFacilityById);
export default withRouter(AdminFacilityById);
