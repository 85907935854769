import React, { Component } from "react";
import UserContext from "../../context/UserContext";
// import { withAuth } from '@okta/okta-react';

class UserSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
    };
  }

  checkAuthentication() {
    // this.props.auth.isAuthenticated().then((response) => {
    //   let authenticated = response;
    //   if (authenticated !== this.state.authenticated) {
    //     this.setState({ authenticated });
    //     this.context.updateUserContext(this.props.auth, authenticated);
    //   }
    // });

    // const authenticated = true;
    // if (authenticated !== this.state.authenticated) {
    //   this.setState({ authenticated });
    //   const simulatedAuth = {
    //     getUser: () =>
    //       Promise.resolve({
    //         email: "testuser@example.com",
    //         name: "Test User",
    //         groups: [],
    //         userId: "test-user-id",
    //         userInfo: {},
    //       }),
    //   };
    //   this.context.updateUserContext(simulatedAuth, authenticated);
    // }

    // Check if the user has been authenticated
    const accounts = this.props.pca.getAllAccounts();
    const authenticated = accounts.length > 0;

    // If the authentication state has changed, then update it
    if (authenticated !== this.state.authenticated) {
      this.setState({ authenticated });

      if (authenticated) {
        const currentUser = accounts[0];
        const idTokenClaims = currentUser.idTokenClaims;
        const groups = idTokenClaims?.groups || [];

        const user = {
          email: currentUser.username,
          name: currentUser.name || "User",
          userId: currentUser.localAccountId || "",
          groups: groups,
          userInfo: currentUser,
        };
        // this.context.updateUserContext(user, authenticated);
      }
    }
  }

  logout = () => {
    console.log(this.props);
    // this.props.auth.logout("/");
    const logoutRequest = {
      account: this.props.pca.getAllAccounts()[0],
      postLogoutRedirectUri: window.location.origin,
    };
    this.props.pca.logoutRedirect(logoutRequest);
  };

  componentDidMount = () => {
    this.checkAuthentication();
  };

  componentDidUpdate = () => {
    this.checkAuthentication();
  };

  componentWillUpdate = () => {
    this.checkAuthentication();
  };

  componentWillMount = () => {
    this.checkAuthentication();
  };

  render() {
    return (
      <span>
        {this.state.authenticated && (
          <button className="au-btn" onClick={this.logout}>
            Logout
          </button>
        )}
      </span>
    );
  }
}
UserSelector.contextType = UserContext;
// export default withAuth(UserSelector);
export default UserSelector;
