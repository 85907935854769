import React, { Component }  from 'react';
import Select from 'react-select';

class JurisdictionSelect extends Component{
    filteredItems(){
      return this.props.state.jurisdictions;
    }
  
    render(){
        return (
        <span>
            <label htmlFor="select1">Jurisdictions</label>
            <Select defaultValue={[]}
                    isMulti
                    name="colors"
                    options={this.filteredItems()}
                    className="basic-multi-select"
                    classNamePrefix="select" 
                    onChange={this.props.handleChange}
                    value={this.props.state.selectedJurisdictions}
                    />
        </span>
        )}
}

export default JurisdictionSelect;