import React, { Component } from "react";
import InfoButton from "../../Common/InfoButton";
// import { withAuth } from '@okta/okta-react';
import { fetchWithAuth } from "../../../common/FetchUtil";

const SUCCESS_MESSAGE = "Added to favourite.";
const ADD_TO_FAVOURITES_INFO_MESSAGE =
  "If you are frequently booking the same courtrooms, you can now add the search selection to your 'Add to favourite' list. Saving you time in the future.";

class AddToFavourites extends Component {
  constructor(props) {
    super(props);
    this.state = { successMessage: null, showInfoMessage: false };
  }

  _toggleInfoMessage = () => {
    this.setState({ showInfoMessage: !this.state.showInfoMessage });
  };

  _getItemIdsOnly = (list) => {
    if (list) {
      return list.map(function (item) {
        return item.value;
      });
    }
    return [];
  };

  _addToFavourites = () => {
    fetchWithAuth(
      this.props.auth,
      process.env.REACT_APP_BASE_API_URL + "/api/users/favourites",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          courthouses: this._getItemIdsOnly(this.props.selectedCourthouses),
          regions: this._getItemIdsOnly(this.props.selectedRegions),
          courtrooms: this._getItemIdsOnly(this.props.selectedCourtrooms),
          hubs: this._getItemIdsOnly(this.props.selectedHubs),
          jurisdictions: this._getItemIdsOnly(this.props.selectedJurisdictions),
          facilities: this._getItemIdsOnly(this.props.selectedFacilities),
        }),
      }
    ).then(() => {
      this.setState({ successMessage: SUCCESS_MESSAGE });
      setTimeout(this._hideSuccessMessage, 3000);
    });
  };

  _hideSuccessMessage = () => {
    this.setState({ successMessage: null });
  };

  render() {
    var successMessage = "";
    if (this.state.successMessage) {
      successMessage = (
        <label style={{ color: "#00A908" }}>{this.state.successMessage}</label>
      );
    }

    return (
      <div className="form-item" style={{ marginTop: 2.5 + "rem" }}>
        <button
          className="au-btn au-btn--secondary"
          onClick={this._addToFavourites}
        >
          Add to Favourites
        </button>
        <InfoButton infoMessage={ADD_TO_FAVOURITES_INFO_MESSAGE} />
        <div>{successMessage}</div>
      </div>
    );
  }
}

// export default withAuth(AddToFavourites);
export default AddToFavourites;
