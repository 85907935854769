import React, { Component } from "react";
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// import { withAuth } from '@okta/okta-react';

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: false,
    };
  }

  _canViewMyCourtrooms = () => {
    return this.props.isAuthenticatedUserResourceOwner;
  };

  _canViewMyBookings = () => {
    return (
      this.props.isAuthenticatedUserResourceOwner ||
      this.props.isAuthenticatedUserRequestor
    );
  };

  _canViewApprovals = () => {
    return this.props.isAuthenticatedUserResourceOwner;
  };

  _canViewFindCourtrooms = () => {
    return (
      this.props.isAuthenticatedUserResourceOwner ||
      this.props.isAuthenticatedUserRequestor
    );
  };

  _canViewCourtroomAdmin = () => {
    return (
      this.props.isAuthenticatedUserSystemAdmin ||
      this.props.isAuthenticatedUserResourceOwner
    );
  };

  _canViewFacilitiesAdmin = () => {
    return this.props.isAuthenticatedUserSystemAdmin;
  };

  componentDidMount = () => {};

  checkAuthentication() {
    // this.props.auth.isAuthenticated().then((response) => {
    //   let authenticated = response;
    //   if (authenticated !== this.state.authenticated) {
    //     this.setState({ authenticated });
    //     this.props.auth.getUser().then((user) => {
    //       this.props.updateStateForViewableLinks(user);
    //     });
    //   }
    // });
    // const simulatedUser = {
    //   email: "testuser@example.com",
    //   name: "Test User",
    //   groups: [],
    //   userId: "test-user-id",
    //   userInfo: {},
    // };

    // Check if the user has been authenticated
    const accounts = this.props.pca.getAllAccounts();
    const authenticated = accounts.length > 0;

    // If the authentication state has changed, then update it
    if (authenticated !== this.state.authenticated) {
      this.setState({ authenticated });

      if (authenticated) {
        const currentUser = accounts[0];
        const idTokenClaims = currentUser.idTokenClaims;
        const groups = idTokenClaims?.groups || [];

        const user = {
          email: currentUser.username,
          name: currentUser.name || "User",
          userId: currentUser.localAccountId || "",
          groups: groups,
          userInfo: currentUser,
        };
        this.props.updateStateForViewableLinks(user);
      }
    }
  }

  componentDidMount = () => {
    this.checkAuthentication();
  };

  componentDidUpdate = () => {
    this.checkAuthentication();
  };

  componentWillUpdate = () => {
    this.checkAuthentication();
  };

  componentWillMount = () => {
    this.checkAuthentication();
  };

  render() {
    return (
      <ul className="au-link-list">
        {this._canViewMyCourtrooms() && (
          <li className="">
            <Link to="/courtrooms">My Courtrooms</Link>
          </li>
        )}
        {this._canViewMyBookings() && (
          <li className="">
            <Link to="/bookings">My Bookings</Link>
          </li>
        )}
        {this._canViewApprovals() && (
          <li className="">
            <Link to="/approvals">Approvals</Link>
          </li>
        )}
        {this._canViewFindCourtrooms() && (
          <li className="">
            <Link to="/findcourtrooms">Find Courtrooms</Link>
          </li>
        )}
        {this._canViewCourtroomAdmin() && (
          <li className="">
            <Link to="/admin/courtrooms">Courtrooms</Link>
          </li>
        )}
        {this._canViewFacilitiesAdmin() && (
          <li className="">
            <Link to="/admin/facilities">Facilities</Link>
          </li>
        )}
      </ul>
    );
  }
}

// export default withAuth(Menu);
export default Menu;
