import React, { Component } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import Bookcourtroom from "../Booking/Bookcourtroom";
import moment from "moment";

class ChangeBookingsGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gridData: [],
      showModal: false,
      viewType: 1,
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  closeModal = (editMode) => {
    this.setState({ showModal: false });
  };

  _changeBooking = (props) => {
    let newEvent = {
      id: props.dataItem.booking.id,
      title: "Change booking",
      start: new Date(props.dataItem.booking.startDate),
      end: new Date(props.dataItem.booking.endDate),
      resourceId: props.dataItem.booking.courtroomId,
      bgColor: "#0a7cb9",
      other: { newEvent: false },
    };

    let resource = {
      id: newEvent.resourceId,
      name: props.dataItem.booking.courtroom.name,
    };

    var startOfWeek = new Date(
      moment(newEvent.start)
        .startOf("isoWeek")
        .subtract(1, "day")
        .format("MM/DD/YYYY 00:00:00")
    );
    var endOfWeek = new Date(
      moment(newEvent.start).endOf("week").format("MM/DD/YYYY 23:59:59")
    );

    this.setState({
      showModal: true,
      viewType: 1,
      startDate: startOfWeek,
      endDate: endOfWeek,
      selectedEvent: newEvent,
      selectedResource: resource,
      editMode: true,
    });
  };

  render() {
    let modal = "";
    if (this.state.showModal && this.props.auth) {
      modal = (
        <Bookcourtroom
          showModal={this.state.showModal}
          viewType={this.state.viewType}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          closeModal={this.closeModal}
          event={this.state.selectedEvent}
          resource={this.state.selectedResource}
          editMode={true}
          auth={this.props.auth}
        ></Bookcourtroom>
      );
    }

    return (
      <div>
        <h2 className="au-sidenav__title">{this.props.title}</h2>
        <Grid
          data={this.props.data}
          skip={this.props.skip}
          take={this.props.take}
          total={this.props.totalItems}
          pageable={true}
          onPageChange={this.props.pageChange}
        >
          <Column field="id" title="Id" width="60px" />
          <Column field="date" title="Date" />
          <Column field="startTime" title="Start Time" />
          <Column field="endTime" title="End Time" />
          <Column field="courthouse" title="Courthouse" />
          <Column field="courtroom" title="Courtroom" />
          <Column field="jurisdiction" title="Jurisdiction" />
          <Column field="status" title="Status" width="200px" />
          <Column
            title="Action"
            width="150px"
            cell={(props) => (
              <td>
                <button
                  className="au-btn au-btn--tertiary"
                  onClick={this._changeBooking.bind(this, props)}
                >
                  Change
                </button>
              </td>
            )}
          />
        </Grid>

        {modal}
      </div>
    );
  }
}

export default ChangeBookingsGrid;
