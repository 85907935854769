// eslint-disable-next-line
import React, { Component } from "react";
// eslint-disable-next-line
import {
  Grid,
  GridColumn as Column,
  GridDetailRow,
} from "@progress/kendo-react-grid";
import moment from "moment";
// import { withAuth } from '@okta/okta-react';
import { fetchWithAuth } from "../../common/FetchUtil";
import UserContext from "../../context/UserContext";

import "./GridDetail.css";

const NOTES_EXCEEDING_LIMIT =
  "Note(s) section exceeds 200 characters. Please shorten the text. ";

class DetailComponent extends GridDetailRow {
  constructor(props) {
    super(props);

    this.state = {
      responseMessage: "",
      approveAll: false,
      rejectAll: false,
    };
  }

  onNotesChange = (event) => {
    this.setState({ notes: event.target.value });
  };

  _validateNotes = () => {
    if (this.state.notes && this.state.notes.length > 200) {
      this.setState({ responseMessage: NOTES_EXCEEDING_LIMIT });
      return false;
    }
    return true;
  };

  _approve = (dataItem) => {
    if (this._validateNotes()) {
      this.setState({ responseMessage: "" });
      this._callEndpoint("approve", dataItem);
    }
  };

  _callEndpoint = (type, dataItem) => {
    fetchWithAuth(
      this.props.auth,
      process.env.REACT_APP_BASE_API_URL +
        "/api/bookings/" +
        dataItem.id +
        "/" +
        type +
        "?userId=" +
        this.context.user.userId,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          notes: this.state.notes,
          includeAllOccurances: this.state.approveAll || this.state.rejectAll,
        }),
      }
    )
      .then((response) => response.json())
      .then((json) => {
        this.setState({ responseMessage: json.message });
        setTimeout(() => {
          dataItem.refreshData();
        }, 2000);
      })
      .catch((error) => {
        if (type === "approve") {
          this.setState({
            responseMessage: "There was an error approving the booking.",
          });
        } else {
          this.setState({
            responseMessage: "There was an error rejecting the booking.",
          });
        }
      });
  };

  _reject = (dataItem) => {
    if (this._validateNotes()) {
      this.setState({ responseMessage: "" });
      this._callEndpoint("reject", dataItem);
    }
  };

  _onApproveAllChanged = () => {
    this.setState({ approveAll: !this.state.approveAll });
  };

  _onRejectAllChanged = () => {
    this.setState({ rejectAll: !this.state.rejectAll });
  };

  render() {
    const dataItem = this.props.dataItem;
    let approveRow = "";
    let rejectRow = "";
    let conflictColumn = (
      <div className="col-xs-6">
        <h3>Conflict details:</h3>
        <div>
          <strong>There are no conflicts with the booking.</strong>
        </div>
      </div>
    );
    if (dataItem.conflicts && dataItem.conflicts.length === 1) {
      let jurisdiction = "Other";

      if (dataItem.conflicts[0].jurisdictionId)
        jurisdiction = dataItem.conflicts[0].jurisdiction.name;

      conflictColumn = (
        <div className="col-xs-6">
          <h3>Conflict details:</h3>
          <div>
            <strong>Date:</strong>{" "}
            {moment(dataItem.conflicts[0].startDate).format("DD/MM/YYYY")}{" "}
          </div>
          <div>
            <strong>Start Time:</strong>{" "}
            {moment(dataItem.conflicts[0].startDate).format("h:mm a")}{" "}
          </div>
          <div>
            <strong>End Time:</strong>{" "}
            {moment(dataItem.conflicts[0].endDate).format("h:mm a")}{" "}
          </div>
          <div>
            <strong>Courthouse:</strong> {dataItem.conflicts[0].courthouse.name}{" "}
          </div>
          <div>
            <strong>Courtroom:</strong> {dataItem.conflicts[0].courtroom.name}{" "}
          </div>
          <div>
            <strong>Jurisdiction:</strong> {jurisdiction}{" "}
          </div>
          <div>
            <strong>Notes:</strong> {dataItem.conflicts[0].notes}{" "}
          </div>
          <div>
            <strong>Status:</strong>{" "}
            {dataItem.conflicts[0].bookingStatusDescription}{" "}
          </div>
          <div>
            <strong>Requested by:</strong>{" "}
            {dataItem.conflicts[0].user.emailAddress}{" "}
          </div>
        </div>
      );
    } else if (dataItem.conflicts && dataItem.conflicts.length > 1) {
      conflictColumn = (
        <div className="col-xs-6">
          <h3>Conflict details:</h3>
          <p>
            There are {dataItem.conflicts.length} in conflict with this booking.
            Cannot show them all.
          </p>
        </div>
      );
    }
    let showBulkCheckboxes =
      dataItem.typeId === 2 ||
      (dataItem.parentBookingId !== null &&
        dataItem.parentBookingId !== undefined);

    let approvalButtonText = "Confirm Approve";
    if (this.state.approveAll) {
      approvalButtonText = "Confirm Approval of all";
    }

    let rejectionButtonText = "Confirm Reject";
    if (this.state.rejectAll) {
      rejectionButtonText = "Confirm Rejection of all";
    }
    if (dataItem.viewType === "approve") {
      approveRow = (
        <div>
          <div className="row">
            <div className="col-xs-12">
              <form>
                <div
                  className="form-item"
                  style={{ width: "auto", position: "relative" }}
                >
                  <label htmlFor="help-comment"> Approval Notes </label>
                  <textarea
                    className="au-text-input au-text-input--block"
                    name="help-comment"
                    id="help-comment"
                    required=""
                    onChange={this.onNotesChange}
                  ></textarea>
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-4">
              <button
                type="button"
                className="au-btn"
                onClick={this._approve.bind(this, dataItem)}
              >
                {approvalButtonText}
              </button>
            </div>
            <div className="col-xs-8">
              {showBulkCheckboxes && (
                <label className="au-control-input au-control-input-markin-top">
                  <input
                    className="au-control-input__input"
                    type="checkbox"
                    checked={this.state.approveAll}
                    onChange={this._onApproveAllChanged}
                  ></input>
                  <span className="au-control-input__text">
                    Approve all occurrences
                  </span>
                </label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">{this.state.responseMessage}</div>
          </div>
        </div>
      );
    }

    if (dataItem.viewType === "reject") {
      rejectRow = (
        <div>
          <div className="row">
            <div className="col-xs-12">
              <label htmlFor="help-comment"> Rejection Notes </label>
              <textarea
                className="au-text-input au-text-input--block"
                name="help-comment"
                id="help-comment"
                required=""
                onChange={this.onNotesChange}
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-4">
              <button
                type="button"
                className="au-btn"
                onClick={this._reject.bind(this, dataItem)}
              >
                {rejectionButtonText}
              </button>
            </div>
            <div className="col-xs-8">
              {showBulkCheckboxes && (
                <label className="au-control-input au-control-input-markin-top">
                  <input
                    className="au-control-input__input"
                    type="checkbox"
                    checked={this.state.rejectAll}
                    onChange={this._onRejectAllChanged}
                  ></input>
                  <span className="au-control-input__text">
                    Reject all occurrences
                  </span>
                </label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">{this.state.responseMessage}</div>
          </div>
        </div>
      );
    }
    return (
      <div className="au-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-6">
              <h3>Booking details:</h3>
              <div>
                <strong>Date:</strong> {dataItem.date}{" "}
              </div>
              <div>
                <strong>Start Time:</strong> {dataItem.startTime}{" "}
              </div>
              <div>
                <strong>End Time:</strong> {dataItem.endTime}{" "}
              </div>
              <div>
                <strong>Courthouse:</strong> {dataItem.courthouse}{" "}
              </div>
              <div>
                <strong>Courtroom:</strong> {dataItem.courtroom}{" "}
              </div>
              <div>
                <strong>Jurisdiction:</strong> {dataItem.jurisdiction}{" "}
              </div>
              <div>
                <strong>Notes:</strong> {dataItem.notes}{" "}
              </div>
              <div>
                <strong>Status:</strong> {dataItem.status}{" "}
              </div>
              <div>
                <strong>Requested by:</strong> {dataItem.user.emailAddress}{" "}
              </div>
            </div>
            {conflictColumn}
          </div>

          {approveRow}

          {rejectRow}
        </div>
      </div>
    );
  }
}

DetailComponent.contextType = UserContext;

// export default withAuth(DetailComponent);
export default DetailComponent;
