import React, { Component }  from 'react';
import Select from 'react-select';
import {filterList} from '../../../common/ListUtil';

class HubSelect extends Component{
    _selectedListComparer(region){
      return region.original.hubs;
    }

    filteredItems(){
      var hubs = this.props.state.hubs;
      var regions = this.props.state.regions;

      if (this.props.state.selectedRegions && this.props.state.selectedRegions.length > 0){
        regions = this.props.state.selectedRegions
      }

      if (this.props.state.selectedHubs && this.props.state.selectedHubs.length > 0){
        hubs = this.props.state.selectedHubs
      }

      return filterList(regions, hubs, this._selectedListComparer)      
    }

    render(){
        return (
        <span>
            <label htmlFor="select1">Hubs</label>
            <Select defaultValue={[]}
                    isMulti
                    name="colors"
                    options={this.filteredItems()}
                    className="basic-multi-select"
                    classNamePrefix="select" 
                    onChange={this.props.handleChange}
                    value={this.props.state.selectedHubs}
                    />
        </span>
        )}
}

export default HubSelect;