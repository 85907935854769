import React, { Component }  from 'react';
import Select from 'react-select';

class RegionSelect extends Component{
    render(){
        return (
        <span>
            <label htmlFor="select1">Regions</label>
            <Select isMulti
                    name="colors"
                    options={this.props.state.regions}
                    className="basic-multi-select"
                    classNamePrefix="select" 
                    onChange={this.props.handleChange}
                    value={this.props.state.selectedRegions}
                    isClearable
                    />
        </span>
        )}
}

export default RegionSelect;