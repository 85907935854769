import React, { Component } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import UserContext from "../../context/UserContext";
import moment from "moment";
import DetailComponent from "./GridDetail";
// import { withAuth } from '@okta/okta-react';
import { fetchWithAuth } from "../../common/FetchUtil";

class Approvals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gridData: [],
      skip: 0,
      take: 20,
    };
  }

  _pageChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take,
    });
  };

  _approve = (props) => {
    props.dataItem.expanded = true;
    props.dataItem.viewType = "approve";
    this.forceUpdate();
  };

  _showDetail = (props) => {
    props.dataItem.expanded = true;
    props.dataItem.viewType = "detail";
    this.forceUpdate();
  };

  _reject = (props) => {
    props.dataItem.expanded = true;
    props.dataItem.viewType = "reject";
    this.forceUpdate();
  };

  _fetchMyApprovals = () => {
    fetchWithAuth(
      this.props.auth,
      process.env.REACT_APP_BASE_API_URL + "/api/users/approvals"
    )
      .then((response) => response.json())
      .then((json) => {
        let bookings = [];
        json.forEach((booking) => {
          let jurisdiction = "Other";

          if (booking.jurisdictionId) {
            jurisdiction = booking.jurisdiction.name;
          }

          bookings.push({
            id: booking.id,
            date: moment(booking.startDate).format("DD/MM/YYYY"),
            startTime: moment(booking.startDate).format("h:mm a"),
            endTime: moment(booking.endDate).format("h:mm a"),
            courthouse: booking.courthouse.name,
            courtroom: booking.courtroom.name,
            status: booking.bookingStatusDescription,
            typeId: booking.bookingTypeId,
            parentBookingId: booking.parentBookingId,
            expanded: false,
            jurisdiction: jurisdiction,
            conflicts: booking.bookingsInConflict,
            notes: booking.notes,
            userId: booking.userId,
            user: booking.user,
            refreshData: this._fetchMyApprovals,
          });
        });

        this.setState({ gridData: bookings });
      });
  };

  componentDidMount = () => {
    this._fetchMyApprovals();
  };

  expandChange = (event) => {
    event.dataItem.expanded = !event.dataItem.expanded;
    event.dataItem.viewType = "detail";
    this.forceUpdate();
  };

  render() {
    return (
      <div className="au-body">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <h2 className="au-sidenav__title">
                Bookings that require approval
              </h2>
              <Grid
                data={this.state.gridData.slice(
                  this.state.skip,
                  this.state.take + this.state.skip
                )}
                skip={this.state.skip}
                take={this.state.take}
                total={this.state.gridData.length}
                pageable={true}
                onPageChange={this._pageChange}
                detail={
                  this.props.auth ? (
                    <DetailComponent auth={this.props.auth} />
                  ) : (
                    <div>Loading...</div>
                  )
                }
                expandField="expanded"
                onExpandChange={this.expandChange}
              >
                <Column field="date" title="Date" width="110px" />
                <Column field="startTime" title="Start Time" width="100px" />
                <Column field="endTime" title="End Time" width="100px" />
                <Column field="courthouse" title="Courthouse" width="120px" />
                <Column field="courtroom" title="Courtroom" width="120px" />
                <Column
                  field="jurisdiction"
                  title="Jurisdiction"
                  width="120px"
                />
                <Column
                  title="Status"
                  width="200px"
                  cell={(props) => (
                    <td>
                      <button
                        className="au-btn au-btn--tertiary"
                        onClick={this._showDetail.bind(this, props)}
                      >
                        {props.dataItem.status}
                      </button>
                    </td>
                  )}
                />
                <Column
                  title="Approve"
                  width="115px"
                  cell={(props) => (
                    <td>
                      <button
                        className="au-btn au-btn--tertiary"
                        onClick={this._approve.bind(this, props)}
                      >
                        Approve
                      </button>
                    </td>
                  )}
                />
                <Column
                  title="Reject"
                  width="115px"
                  cell={(props) => (
                    <td>
                      <button
                        className="au-btn au-btn--tertiary"
                        onClick={this._reject.bind(this, props)}
                      >
                        Reject
                      </button>
                    </td>
                  )}
                />
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Approvals.contextType = UserContext;

// export default withAuth(Approvals);
export default Approvals;
